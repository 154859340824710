/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.jedidesk-chat__position {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10001;

  @media (max-width: 750px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.jedidesk-chat__position--open {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  @media (max-width: 750px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.jedidesk-frame-position {
  position: relative;
  width: 385px;
  height: 820px;

  @media (max-height: 1000px) {
    height: 600px;
  }

  @media (max-height: 620px) {
    height: 438px;
  }

  @media (max-width: 750px) {
    height: 100%;
    width: 100%;
  }

  &--close {
    width: 90px;
    height: 90px;

    @media (max-width: 750px) {
      height: 90px;
      width: 90px;
      -ms-flex-item-align: end;
      align-self: flex-end;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }
}

#jedidesk-iframe {
  width: calc(100%);
  height: calc(100%);
  border: none;
}
